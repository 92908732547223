import React, {useEffect} from 'react';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import AuthRouter from './router/authRouter';
import Router from './router/router';
import { isLogged } from './services/auth.service';
import {updateOneSignalId} from './services/user.service';
import loginStore from './store/loginStore';
import routeStore from './store/routeStore';

import NavBar from './components/navBar/navBar.component';

import OneSignal from 'react-onesignal';

import {useTranslation} from "react-i18next";


function App() {
  const login = loginStore(state => state.login);
  const setLogin = loginStore(state => state.setLogin);
  const route = routeStore(state => state.route);
  const {i18n} = useTranslation();

  

  useEffect(() => {
    i18n.changeLanguage(navigator.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  OneSignal.on('notificationDisplay', function(event) {
    //console.log('notificationDisplay', event)
    const notification = event;
    const options = {
      body: notification.content,
      icon: notification.icon,
      // Otras opciones de personalización de la notificación
    };
    new Notification(notification.heading, options);
  });

  useEffect(() => {
    if (login) {

      OneSignal.init({
        appId: "6df19771-e803-4a9a-bf1b-c0e507832158",
        notifyButton: {
          enable: true,
          position: 'bottom-left',
          offset: {
            bottom: '120px',
            left: '20px'
          }
        },
        subdomainName: "https://workplace.mybeatcoach.com",
      }).then(async res => {
        OneSignal.getNotificationPermission().then(async res => {
          await OneSignal.getUserId( async (userId) => {
            updateOneSignalId(userId).then(res => {
            }).catch(err => {
            })
            // Make a POST call to your server with the user ID
          });
        });
        await OneSignal.registerForPushNotifications().then(async () => {
          await OneSignal.isPushNotificationsEnabled(async (isEnabled) => {
            if (!isEnabled) {
              // user has subscribed
              await OneSignal.getUserId( async (userId) => {
                // Make a POST call to your server with the user ID
              });
            }
          });
        }).catch(err => {
          console.log('error registeronesignal')
        });
      }).catch(err => {
        console.log('Error to crate onesignal')
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  useEffect(() => {
    isLogged().then(res => {
      setLogin(true);
    }).catch(err => {
      setLogin(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
        <div className="App">
          <BrowserRouter>
            {(login && route !== '/rating') &&
            <div className={'containerNav'}>
              <NavBar/>
            </div>
            }


            <div className={(login && route !== '/rating') ? 'containerRouter' : 'containerRouterNoNav'}>
              {login ?
                  <Router/>
                  :
                  <AuthRouter/>
              }
            </div>
          </BrowserRouter>
        </div>
  );
}

export default App;
