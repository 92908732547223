import axios from 'axios';
import { Const } from '../utils/variables';

const axiosInstance = axios.create({baseURL: `https://${Const.baseURL}/api/`});

/**
 * Recoge los chats ya se hayan cerrado o esten activos (0 = cerrados || 1 = abiertos)
 * */
export const getConversations = async (status) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get(`channels?status=${status}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge los mensajes de una conversacion
 * */
export const getChannelMessages = async (channelId) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get(`channels/${channelId}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Envia un mensaje nuevo
 * */
export const sendMessage = async (channelId, userId, message, file) => {
    let token = await localStorage.getItem('token');
    let headers;
    const formData = new FormData();
    formData.append('channel_id', channelId);
    formData.append('user_id', userId);
    formData.append('content', message);
    formData.append('file', file);

    if (file === null) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    } else {
        headers = {
            'Accept':'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }

    let dataMessage = {
        "channel_id": channelId,
        "user_id": userId,
        "content": message
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('messages', file === null ? dataMessage : formData , {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

//Envia un mensaje en la conversación de IA
export const sendIaMessage = async (data, channelId, userId, type, message) => {
    let token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    }


    const formData = new FormData();
    formData.append('channel_id', channelId);
    formData.append('user_id', userId);
    formData.append('content', message);
  
    let dataMessage = {
      "channel_id": channelId,
      "user_id": userId,
      "content": message
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post('create_ia_message', dataMessage , {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }


/**
 * Finaliza una sesion
 * */
export const finishSession = async (idChat) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get(`channels/finalize/${idChat}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Valorar una sesion
 * */
export const rateSession = async (idChat, rate, speciality) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    const data = {
        videocall_rating: rate,
        speciality_id: speciality
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post(`channels/${idChat}/videocall_rate`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las conversaciones finalizadas
 * */
export const getFinishedChats = async () => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('coaches/channels/my_ended_channels', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Banea un usuario
 * */
export const blockUser = async (idUser, idChannel, reason) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    const data = {
        user_id: idUser,
        channel_id: idChannel,
        reason: reason
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('bann_user', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * Cambia el estado del coach al entrar a videollamada
 * */
export const changeStatusToEnterVideoCall = async (isChannel) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    const data = {
        channel_id: isChannel
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('start_videocall', data,  {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge el id de una videollamada
 * */
export const getVideocallUuid = async (idChannel) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`get_videocall_uuid/${idChannel}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crea un nuevo chat con el coach
 */
export const createChat = async (idCoach) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    const data = {
        coach_id: idCoach
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post(`start_conversation`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Crea un nuevo chat de IA
 * */
export const createIAChat = async (idCoach, idThread, language, speciality) => {
    let token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    }
    let data = {
      coach_id: idCoach,
      thread_id: idThread,
      language: language,
      speciality: speciality
    }
    return new Promise((resolve, reject) => {
      axiosInstance.post('start_conversation_ia', data, {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

/**
 * Recoge los chats abiertos de un usuario
 */
export const getPrivateChats = async () => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`my_coaches`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las conversaciones cerradas del usuario
 */
export const getCloseConversations = async (status) => {
    let token = await localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    return new Promise((resolve, reject) => {
        axiosInstance.get(`get_ended_channels_user_v2?status=${status}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Envia la valoracion del usuario
 * */
export const rateCoach = async (idChat, rate, statusRate, description) => {
    let token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  
    const data = {
      rate: rate,
      status_rate: statusRate,
      descriptionRate: description
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post(`channels/${idChat}/rate`, data, {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }


/**
 * Envia al servidor que el usuario esta escribiendo para poder avisar al otro usuario de la conversacion de que esta escribiendo
 */
export const userStartWriting = async (channelId, userId) => {
    let token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  
    const data = {
      channel_id: channelId,
      user_id: userId
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post(`user_start_writing`, data, {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }


/**
 * Envia al servidor que el usuario ha dejado de escribir para avisar al otro usuario
 */
export const userEndWriting = async (channelId, userId) => {
    let token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  
    const data = {
      channel_id: channelId,
      user_id: userId
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post(`user_end_writing`, data, {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  //Envia un mensaje en la conversación de IA del user lo gurada y crea tambien el mensaje de la ia
export const sendUserIaMessage = async (data, channelId, userId, type, messageCript, messageDecript) => {
    let token = await localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  
    let dataMessage = {
      "channel_id": channelId,
      "user_id": userId,
      "content": messageCript,
      "message": messageDecript
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post('createNewMessageUserIa', dataMessage , {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  