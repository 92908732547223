import React from 'react';

import './historyItem.css';
import * as moment from 'moment';
import { Const } from '../../utils/variables';

// Muestra el compoente de las conversaciones ya cerradas
const HistoryItem = ({image, name, company, date, press, session}) => {

    return (
        <div className={'containerHistoryItem'} onClick={press}>
            <div className={'containerImageHistoryItem'}>
                <img className={'imageHistoryItem'} alt={''} src={`https://${Const.baseURL}/${image}`}/>
            </div>


            <div className={'containerTextHistoryItem'}>
                <p className={'nameHistoryItem'}>{name}</p>
                <p className={'companyHistoryItem'}>{company}</p>
            </div>

            <p className={'dateHistoryItem'}>{session ? moment(date).format('dddd, DD MMMM - HH:mm') : moment(date).format('DD/MM/YYYY')}</p>
        </div>
    )
}

export default HistoryItem;
