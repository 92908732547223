const translations = {
    es: {
      translation: {
        password: "Contraseña",
        terms: "términos y condiciones",
        accept: "Acepto los",
        of: "de",
        forgottenPassword: "¿Olvidaste tu contraseña?",
        login: "Iniciar sesión",
        textRequestEmail: "Introduce tu correo y en breve te enviaremos una nueva contraseña de acceso.",
        rememberPass: "Recuperar contraseña",
        textRequestEmail2: "Te enviaremos un código para reestablecer la contraseña a",
        accept2: "Aceptar",
        cancel: "Cancelar",
        loginContinue: 'Acepta los términos y condiciones para continuar con el login.',
        generateKeys: "Generando claves de cifrado",
        errorAccessCode: "El código de acceso es incorrecto.",
        verifyCodeText1: 'Introduce el código de acceso recibido en',
        verifyCodeText2: 'Si en breves segundos no has recibido tu código de acceso, consulta la carpeta spam o correo no deseado.',
        verify: 'Verificar',
        search: 'Buscar',
        speciality: 'Especialidad',
        specialities: 'Especialidades',
        initChat: 'Iniciar conversación',
        filters: 'Filtros',
        apply: 'Aplicar',
        myCoaches: 'Mis Coaches...',
        notActiveChats: 'No tienes chats activos',
        history: 'Historial...',
        history2: 'Historial',
        aboutMyBeatCoach: 'Acerca de MyBeatCoach',
        faq: 'Preguntas frequentes',
        changePass: 'Cambiar contraseña',
        logout: 'Cerrar sesión',
        howWorks: '¿Cómo funciona el servicio?',
        textAbout: 'Elige al Top Coach que mejor se adapte a tu reto en cada momento y chatea de manera confidencial tantas veces como quieras. Con MyBeatCoach, tienes acceso a diferentes profesionales que te acompañarán a conseguir tus objetivos profesionales y personales y aumentar así tu Bienestar.',
        discoverSpecialities: 'Conoce nuestras especialidades de Coaching',
        changePasswordCorrectly1: 'Contraseña cambiada correctamente',
        changePasswordCorrectly2: 'Su contraseña ha sido cambiada correctamente',
        changePasswordError1: 'Error al cambiar la contraseña',
        changePasswordError2: 'No pudimos cambiar su contraseña, intente nuevamente mas tarde.',
        newPass: 'Nueva contraseña',
        enterNewPassword: 'Introduce una nueva contraseña',
        question1: '¿Cómo funciona?',
        response1: 'Nuestra App MyBeatCpach te brinda acceso a conversaciones de coaching personalizadas por chat con Top Coaches certificados para impulsar tu bienestar y alcanzar tus retos personales y profesionales.\n' +
          'Puedes mantener tantas conversaciones de chat como quieras con cuántos coaches elijas e incluso conocerlos por videollamada.',
        question2: '¿Cómo contacto con un coach?',
        response2: 'Puedes explorar los diferentes perfiles de nuestros coaches certificados, conocer sus especialidades y seleccionar el perfil más adecuado para ti en cada momento y en función de tu objetivo. A continuación podrás contactarlo directamente a través de mensajes y solicitar si lo necesitas, una videollamada para conocer a tu coach.',
        question3: '¿Cuándo están disponibles los coaches?',
        response3: 'Los coaches están disponibles en diferentes momentos del día y días de la semana en una franja horaria de 10:00h a 18:00h los días laborables de lunes a viernes. Puedes conversar con el coach que está disponible en el momento que tú necesites o consultar la disponibilidad del coach que tú prefieras para conversar con él.',
        question4: '¿Cómo escojo a mi coach?',
        response4: 'Todos nuestros Top Coaches están certificados, tienen una amplia experiencia en coaching y están preparados para coachear todos los retos. Además, están especializados en algunas áreas que te pueden ayudar a hacer tu selección.',
        question5: '¿Puedo hacer coaching por video llamada?',
        response5: 'El coaching por chat ofrece la ventaja de la reflexión pausada, mayor privacidad y flexibilidad horaria, permitiendo un proceso de coaching más adaptado a tus necesidades.\n' +
          'La videollamada está disponible si necesitas conocer al coach y establecer vuestra relación de confianza.',
        question6: '¿Qué pasa si no puedo responder a mi coach en el momento?',
        response6: 'Con MyBeatCoach, disfrutas de conversaciones de coaching flexibles, adaptadas a tu horario. Si no puedes responder de inmediato, tu coach estará disponible para ti hasta que termine su disponibilidad.',
        question7: '¿Qué ocurre si mi coach deja de estar disponible y no hemos acabado la conversación?',
        response7: 'El coach cerrará la conversación en el caso de que termine su horario y podrás volver a contactarlo cuando esté disponible o contactar con otro coach.',
        myProfile: 'Mi perfil',
        changePassword: 'Cambio de contraseña',
        init: 'Inicio',
        ratings: 'valoraciones',
        available: 'Disponible',
        notAvailable: 'No disponible',
        busy: 'Ocupado',
        mySpecialities: 'Mis especialidades',
        initChat2: 'Iniciar chat',
        newChat: 'Nuevo chat.',
        newChatText: '¿Quieres iniciar un nuevo chat con el coach?',
        coachNotAvailableText: 'El coach no esta disponible en este momento, intentalo cuando este disponible.',
        ratingChatText: 'Se necesita valorar la conversación para poder volver a iniciarla.',
        errorInitChatText: 'Se ha producido un error al generar la conversación, intentalo más tarde.',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miercoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sabado',
        sunday: 'Domingo',
        and: 'y',
        typing: 'Escribiendo...',
        serverError: 'Error de servidor',
        serverErrorText: 'En este momento no se puede conectar con el servidor, por favor comprueba tu conexión a internet e intentalo más tarde.',
        errorCoachIaMessage: 'Tu coach no ha podido contestarte en este momento, por favor inténtalo más tarde.',
        sorry: 'Lo sentimos.',
        sorryText: 'No puedes entrar a una videollamada de un chat finalizado.',
        close: 'Cerrar',
        titleSessions: 'Mis sesiones',
        text1Sessions: 'Próximas sesiones con tu Coach',
        text2Sessions: 'Sesiones realizadas con tu Coach',
        nameTabSessions: 'Sesiones',
        scheduleSession: 'Agenda tu próxima sesión con tu Coach',
        errorAccessCall: 'En este momento no se puede acceder a la videollamada, intentalo cuando llegue la hora de inicio.',
        
        messages: 'Chats',
        history3: 'Historial de mensajes',
        aboutMbc: 'Acerca de MyBeatCoach',
        profile: 'Perfil',
        sessions: 'Sesiones',
        notMessagesByRead: 'No tienes mensajes sin leer',
        notCanScheduleSession: 'No se puede agendar una nueva sesión hasta que finalices la sesión actual.',
        yourGoals: 'Tus objetivos de desarrollo',
        newChatMessages: 'Nuevos mensajes de Chat',
        initInmediatlyChat: 'Inicia de forma inmediata una conversación con tu Coach'
        
      },
    },
    en: {
      translation: {
        password: "Password",
        terms: "terms and condicitons",
        accept: "I accept the",
        of: "of",
        forgottenPassword: "Forgotten your password?",
        login: "Log in",
        textRequestEmail: "Enter your email and we will send you a new access password shortly.",
        rememberPass: "Recover password",
        textRequestEmail2: "We will send you a code to reset the password to",
        accept2: "Accept",
        cancel: "Cancel",
        loginContinue: 'Accept the terms and conditions to continue with the login.',
        generateKeys: "Generating encryption keys",
        errorAccessCode: "The access code is incorrect.",
        verifyCodeText1: 'Enter the access code received at',
        verifyCodeText2: 'If you do not receive your access code within a few seconds, check your spam or junk folder.',
        verify: 'Verify',
        search: 'Search',
        speciality: 'Speciality',
        specialities: 'Specialities',
        initChat: 'Start conversation',
        filters: 'Filters',
        apply: 'Apply',
        myCoaches: 'My Coaches...',
        notActiveChats: 'You don\'t have any active chats',
        history: 'History...',
        history2: 'History',
        aboutMyBeatCoach: 'About MyBeatCoach',
        faq: 'Frequent questions',
        changePass: 'Change password',
        logout: 'Log out',
        howWorks: 'How does the service work?',
        textAbout: 'Choose the Top Coach that best fits your challenge at any given moment and chat confidentially as many times as you like. With MyBeatCoach, you have access to various professionals who will help you achieve your personal and professional goals, thus enhancing your well-being.',
        discoverSpecialities: 'Discover our coaching specialities',
        changePasswordCorrectly1: 'Password changed correctly',
        changePasswordCorrectly2: 'Your password has been changed successfully',
        changePasswordError1: 'Error changing password',
        changePasswordError2: 'We couldn\'t change your password; please try again later.',
        newPass: 'New password',
        enterNewPassword: 'Enter a new password',
        question1: 'How does it work?',
        response1: 'Our MyBeatCoach app provides you with access to personalised coaching conversations via chat with certified Top Coaches to boost your well-being and achieve your personal and professional goals.\n' +
          'You can engage in as many chat conversations as you like with the coaches of your choice and even meet them via video call.',
        question2: 'How do I contact a coach?',
        response2: 'You can explore the different profiles of our certified coaches, learn about their specialisms, and select the profile that best suits you at any given time based on your objective. You can then contact them directly via messages and, if needed, request a video call to get to know your coach.',
        question3: 'When are the coaches available?',
        response3: 'The coaches are available at various times of the day and on different days of the week, within the hours of 10:00 to 18:00 on weekdays, from Monday to Friday. You can chat with the coach who is available at the time you need or check the availability of your preferred coach to arrange a conversation.',
        question4: 'How do I choose my coach?',
        response4: 'All our Top Coaches are certified, have extensive experience in coaching, and are equipped to handle all types of challenges. Additionally, they are specialised in certain areas that can help you make your selection.',
        question5: 'Can I have coaching via video call?',
        response5: 'Chat coaching offers the advantage of thoughtful reflection, greater privacy, and flexible timing, allowing for a coaching process that is more tailored to your needs.\n' +
          'Video calls are available if you need to meet the coach and establish a trusting relationship.',
        question6: 'What happens if I can\'t respond to my coach immediately?',
        response6: 'With MyBeatCoach, you enjoy flexible coaching conversations tailored to your schedule. If you can’t respond immediately, your coach will remain available to you until their availability ends.',
        question7: 'What happens if my coach becomes unavailable before we\'ve finished the conversation?',
        response7: 'The coach will end the conversation if their availability finishes, and you can reach out to them again when they are next available or contact another coach.',
        myProfile: 'My profile',
        changePassword: 'Password change',
        init: 'Init',
        ratings: 'ratings',
        available: 'Available',
        notAvailable: 'Not available',
        busy: 'Busy',
        mySpecialities: 'My specialities',
        initChat2: 'Init chat',
        newChat: 'New chat.',
        newChatText: '¿Would you like to start a new chat with the coach??',
        coachNotAvailableText: 'The coach is not available at the moment; please try again when they are.',
        ratingChatText: 'Rating the conversation is required to be able to start it again.',
        errorInitChatText: 'An error occurred while generating the conversation; please try again later.',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        and: 'and',
        typing: 'Typing...',
        serverError: 'Server error',
        serverErrorText: 'At the moment, we cannot connect to the server. Please check your internet connection and try again later.',
        errorCoachIaMessage: 'Your coach is unable to respond at the moment; please try again later.',
        sorry: 'We\'re sorry.',
        sorryText: 'You cannot join a video call for a completed chat.',
        close: 'Close',
        titleSessions: 'My sessions',
        text1Sessions: 'Upcoming sessions with your coach',
        text2Sessions: 'Completed sessions with your coach',
        nameTabSessions: 'Sessions',
        scheduleSession: 'Schedule your next session with your coach',
        
        messages: 'Chats',
        history3: 'Messages history',
        aboutMbc: 'About MyBeatCoach',
        profile: 'Profile',
        sessions: 'Sessions',
        notMessagesByRead: 'You have no unread messages',
        errorAccessCall: 'The video call is currently inaccessible, please try again at the start time.',
        notCanScheduleSession: 'A new session cannot be scheduled until you finish the current session.',
        yourGoals: 'Your development goals.',
        newChatMessages: 'New chat messages.',
        initInmediatlyChat: 'Start a conversation with your coach immediately.'
      },
    },
  };
  
  export default translations;
  