import React, {useEffect, useRef, useState} from 'react';
import './verifyCode.css'
import { useLocation, useNavigate } from 'react-router-dom';
import loginStore from "../../../store/loginStore";

import OriginalLogo from '../../../assets/img/logos/originalLogoAuth.png';

import Button from '../../../components/button/button.component';

const VerifyCode = () => {
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const setLogin = loginStore(state => state.setLogin);

    const [sended, setSended] = useState(false)

    const location = useLocation();
    const navigate = useNavigate();

    let refInput1 = useRef({});
    let refInput2 = useRef({});
    let refInput3 = useRef({});
    let refInput4 = useRef({});

    const verifyCode = () => {
        if (Number(location.state.user.user.two_factor_auth_code) === Number(code1+code2+code3+code4)) {
            if (location.state.user.user.profile != null && location.state.user.user.badge != null) {
                localStorage.setItem('token', location.state.token);
                localStorage.setItem('USER', JSON.stringify(location.state.user));
                navigate('/')
                setLogin(true);
            } else {
                setSended(true)
            }
            /**/
        } else {
            alert('El código de acceso es incorrecto')
            setCode1('')
            setCode2('')
            setCode3('')
            setCode4('')
        }
    }

    const handleMessageFromIframe = (event) => {
        if (event.data.type === 'thank-you-screen-redirect') {
            setSended(false)
            localStorage.setItem('token', location.state.token);
            localStorage.setItem('USER', JSON.stringify(location.state.user));
            navigate('/')
            setLogin(true);
        }
      };

      useEffect(() => {
        // Agregar event listener para el evento de envío del formulario
        window.addEventListener('typeform:submit', handleFormSubmit);
    
        // Eliminar el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('typeform:submit', handleFormSubmit);
        };
      }, []);
    
      const handleFormSubmit = () => {
        // Aquí puedes realizar alguna acción después de que se envía el formulario
        //console.log('El formulario se ha enviado');
    
        // Por ejemplo, puedes redirigir a una página de agradecimiento
        // window.location.href = '/gracias';
    
        // O puedes cerrar el Typeform incrustado si lo estás mostrando en un modal
        // closeModal();
      };
    


    return (
        sended ? 
        <iframe
            title={'typeform'}
            id={'iframe'}
            style={{width: '100%', height: '100vh'}}
            src={`https://ni5hkfnr35f.typeform.com/to/FM5sB7mc#user_id=${location.state.user.user.id}&source=web_staging`}
            onLoad={() => {
                window.addEventListener('message', handleMessageFromIframe);
            }}
        >

        </iframe>
        :
        <div className='containerVerifyCode'>
            <img src={OriginalLogo} alt={''} />
            <p className='titleVerifyCode'>Introduce el código de acceso recibido en {location.state.user.user.email}</p>

            <p className='textVerifyCode'>Si en breves segundos no has recibido tu código de acceso, consulta la carpeta spam o correo no deseado.</p>

            <div className='containerInputs'>
                <input
                    ref={ref => refInput1 = ref}
                    className='inputVerifyCode'
                    value={code1}
                    maxLength={1}
                    onChange={value => {
                        setCode1(value.target.value);
                        if (value.target.value.length > 0) {
                            refInput2.focus();
                        }
                    }}
                />
                <input
                    ref={ref => refInput2 = ref}
                    className='inputVerifyCode'
                    value={code2}
                    maxLength={1}
                    onChange={value => {
                        setCode2(value.target.value);
                        if (value.target.value.length > 0) {
                            refInput3?.focus();
                        } else {
                            refInput1?.focus();
                        }
                    }}
                />
                <input
                    ref={ref => refInput3 = ref}
                    className='inputVerifyCode'
                    value={code3}
                    maxLength={1}
                    onChange={value => {
                        setCode3(value.target.value);
                        if (value.target.value.length > 0) {
                            refInput4?.focus();
                        } else {
                            refInput2?.focus();
                        }
                    }}
                />
                <input
                    ref={ref => refInput4 = ref}
                    className='inputVerifyCode'
                    value={code4}
                    maxLength={1}
                    onChange={value => {
                        setCode4(value.target.value);
                        if (value.target.value.length === 0) {
                            refInput3?.focus();
                        }
                    }}
                />
            </div>

            <div className='buttonVerifyCode'>
                <Button
                    text={'Verificar'}
                    press={() => verifyCode()}
                />
            </div>
            
        </div>
    )
}

export default VerifyCode;