import React from 'react';

import './chatItem.css';
import {IconAssets} from '../../utils/ImageAssets';

import DotQuantity from "../dotQuantity/dotQuantity.component";
import Avatar from '../avatar/avatar.component';
import {useTranslation} from "react-i18next";

import moment from 'moment';

// Muestar el componente de las conversaciones
const ChatItem = ({image, fullName, timeConnect, status, size, quantitySpecialities, unreadMessages, organization, timeLastMessage, press, idUserLastMessage, idCurrentUser, lastMessageIsRead, coachIsIa}) => {

    const {t} = useTranslation();

    return (
        <div className={'containerChatItem'} onClick={press}>
            <div className={'containerImageChatItem'}> 
                <Avatar image={image} status={status}/>
            </div>

            <div className={'containerTextChatItem'}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {(idUserLastMessage !== null && idCurrentUser !== idUserLastMessage && !lastMessageIsRead && !coachIsIa) &&
                        <img style={{width: 18, height: 18, marginRight: 5}} alt={''} src={IconAssets.bell}/>
                    }
                    <p className={'nameChatItem'}>{fullName}</p>
                </div>
                <div className='containercenterChatItem'>
                    {/*<div className={'containerSpecialities'}>
                        {quantitySpecialities + ' ' + textSpecialities}
                    </div>*/}

                    {status === 'Online' || status === 'Disponible' ?
                        <div className={'containerInitChat'}>
                            {t('initChat')}
                        </div>
                        :
                        null
                    }
                </div>
                {/* <p className={'companyChatItem'}>Empresa {organization}</p> */}
                <p className={'lastMessageChatItem'} >{timeConnect}</p>
            </div>

            <div className={'containerDetailsChatItem'}>
                <p className={'timeChatItem'}>{timeLastMessage === '' ? '' : moment(timeLastMessage).format('DD/MM/yyyy')}</p>
                <DotQuantity
                    size={'big'}
                    focus={false}
                    quantity={(unreadMessages && unreadMessages.hasOwnProperty('messages')) ? unreadMessages.messages : unreadMessages}
                />
            </div>
        </div>
    )
}

export default ChatItem;
