import React, {useState, useEffect} from 'react';

import '../login/login';
import { IconAssets } from '../../../utils/ImageAssets';

import {useNavigate} from 'react-router-dom';
import {message} from 'antd';

import Input from '../../../components/input/input.compnent';
import Button from '../../../components/button/button.component';

import {changePassword } from '../../../services/user.service';

// Pagian para cambiarla contraseña
const ChangePassword = () => {
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [disable, setDisable] = useState('');

    const navigate = useNavigate();

    // Se ejecuta cada vez que cambian los campos password1 y password2
    // Si los campos estan llenos activa el boton en caso contrario lo desactiva
    useEffect(() => {
        if (!password1 || !password2) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [password1, password2]);

    // Compruba que las contraseñas nuevas coincidan, en el caso de coincidir navega a la siguiente vista sino muestra mensaje de error de que las contraseñas no coinciden
    const goLogin = () => {
        if (window.confirm('¿Estas seguro de cambiar la contraseña?')) {
            if (password1 === password2) {
                changePassword(password1).then(res => {
                    navigate('/')
                }).catch(err => {
    
                })
            } else {
                message.error('Las contraseñas no coinciden.');
            }
        }
        
    }

    return (
        <div className={'containerLogin'}>
            {/* <img className={'logo'} src={ImageAssets.originalLogoAuth}/> */}
            <div class="containerData">
                <h1>Cambiar contraseña</h1>
                <form className={'formLogin'}>
                    <Input
                        iconLeft={IconAssets.pass}
                        label={'Nueva contraseña'}
                        placeholder={'Nueva contraseña'}
                        type={'password'}
                        changeValue={value => setPassword1(value)}
                    />

                    <Input
                        iconLeft={IconAssets.pass}
                        label={'Confirmar nueva contraseña'}
                        placeholder={'Confirmar nueva contraseña'}
                        type={'password'}
                        changeValue={value => setPassword2(value)}
                    />

                    <Button
                        disabled={disable}
                        text={'Cambiar contraseña'}
                        press={goLogin}
                    />
                </form>
            </div>
        </div>
    )
}

export default ChangePassword;
