import React, {useState, useEffect} from 'react';
import './chats.css';
import {useNavigate} from 'react-router-dom';
import { Crypt } from 'hybrid-crypto-js';

import Searcher from '../../components/searcher/searcher.component';
import ChatItem from '../../components/chatItem/chatItem.component';

import { getCloseConversations } from '../../services/chat.service';
import {useTranslation} from "react-i18next";

const Chats = () => {
    const [user, setUser] = useState();
    const [chats, setChats] = useState([]);
    const [allChats] = useState([]);
    const [lastMessages, setLastMessages] = useState([]);

    const navigate = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
      //Eliminamos el id del chat que acabamos de crear
      localStorage.removeItem('idNewChat')
      
      setUser(JSON.parse(localStorage.getItem('USER')))

      getCloseConversations(1).then(res => {
        setChats(res.data.channels)
        getLastMessages(res.data.channels)

      }).catch(err => {
        if (err.response.status === 469) {
          navigate('/rating', {state: {idChat: err.response.data.channelId, coach: err.response.data.coach}})
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /*Recoge y desencripta los ultimos mensajes de cada conversacion*/
    const getLastMessages = async (chatsLastMessage) => {
      //setLastMessages([])
      let arrayMessages = []
      let getPrivatekey = null
      getPrivatekey = await localStorage.getItem('privatekey')
  
      /*Recorre los chats*/
        chatsLastMessage.forEach(chat => {
          let messageDecripted
          let chatMessages = chat.Messages.reverse()
          /*Comprueba si el chat tiene mensajes*/
          if (chat != null) {
            if (chatMessages.length > 0) {
              /*Si el chat tiene mensajes recoge de local estorage la clave privada para desencriptar el mensaje*/
              let crypt = new Crypt();
              /*Desencripta el mensaje y lo guarda en un array en el mismo orden en el que se han guardado las conversaciones*/
              try {
                messageDecripted = crypt.decrypt(getPrivatekey, chatMessages[chatMessages.length - 1].content);
                //setLastMessages(lastMessages => [...lastMessages, messageDecripted.message]);
                arrayMessages.push({message: messageDecripted.message, read: chatMessages[chatMessages.length - 1].read_at, userId: chatMessages[chatMessages.length - 1].user_id})
              } catch (err) {
                //setLastMessages(lastMessages => [...lastMessages, chatMessages[chatMessages.length - 1].content]);
                arrayMessages.push({message: chatMessages[chatMessages.length - 1].content, read: chatMessages[chatMessages.length - 1].read_at, userId: chatMessages[chatMessages.length - 1].user_id})
              }
            } else {
              /*Si el chat no tiene mensajes guarda una posicion en el array vacia para no descuadrad los ultimos mensajes con los chats*/
              //setLastMessages(lastMessages => [...lastMessages, '']);
              arrayMessages.push({message: '', read: false, userId: null})
            }
          }
        })
        setLastMessages(arrayMessages)
    }

  const filterCoaches = (value) => {
    if (value !== '') {
        let newChats = chats.filter(chat => {
            let fullName = chat.name + ' ' + chat.lastname
            return fullName.toLowerCase().startsWith(value.toLowerCase());
        })
        setChats(newChats);
    }
    else {
        setChats(allChats);
    }
  }

    return (
        <div>
            <div className={'containerChats'}>
                <p className='titleChats'>{t('messages')}</p>

                <Searcher changeValue={value => filterCoaches(value)}/>
                <div className={'containerItemChats'}>

              {(chats !== null && lastMessages != null) &&
                chats.map((coach, index) => {
                  if (coach != null) {
                    console.log('this is a coahc', coach)
                    return (
                        <ChatItem
                            image={coach.User.image}
                            fullName={coach.User.name + ' ' + coach.User.lastname}
                            status={coach.User.status}
                            timeConnect={coach.Messages && coach.Messages.length > 0 && chats.length === lastMessages.length ? lastMessages[index].message : ''}
                            quantitySpecialities={7}
                            idCurrentUser={user.id}
                            idUserLastMessage={lastMessages[index] ? lastMessages[index].userId : null}
                            lastMessageIsRead={lastMessages[index] ? lastMessages[index].read : null}
                            newMessages={coach.new_messages}
                            coachIsIa={coach.User.is_ia}
                            press={() => {
                              if (coach.User.is_ia) {
                                navigate('/chati', {state: {idChat: coach.id, thread_id: coach.thread_id, coach: coach.User, writer: true}})
                              } else {
                                navigate('/chat', {state: {idChat: coach.id, coach: coach.User, writer: true}})
                              }
                            }}
                            timeLastMessage={coach.Messages.length > 0 ? coach.Messages[0].createdAt : ''}
                        />
                    )
                  } else {
                    return null
                  }
                })
                }
                </div>

              <div className='floatButtonChats' onClick={() => navigate('/coaches')}>
                  +
              </div>

            </div>
        </div>
    )
}

export default Chats;