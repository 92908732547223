import React, {useEffect, useState, useRef} from 'react';
import io from 'socket.io-client';
import {useNavigate} from 'react-router-dom';

import './navBar.css';
import { ImageAssets } from '../../utils/ImageAssets';

import {useLocation} from 'react-router-dom';

import UserNavBar from '../userNavBar/userNavBar.component';
import Menu from '../menu/menu.component';
import Button from '../button/button.component'
import CloseSesion from '../closeSesion/closeSesion.component';
import {getProfile} from "../../services/user.service";
import { Const } from '../../utils/variables';
import {useTranslation} from "react-i18next";

// Muestra el componente del navbar
const NavBar = () => {
    const [user, setUser] = useState(null);
    const [imageUser, setImageUser] = useState(null);
    const [status, setStatus] = useState('No disponible');
    const [unreadMessages, setUnreadMessages] = useState(0)

    const location = useLocation();
    const navigate = useNavigate();

    const socketRef = useRef(null);

    const {t} = useTranslation();


    useEffect(() => {

        // Recoge el usuario
        getUserProfile();

        // Recoge el usuario del localstorage
        let userStorage = localStorage.getItem('USER');
        setUser(JSON.parse(userStorage));


        //CONECTA EL SOCKET
        let token =localStorage.getItem('token');
        socketRef.current = io(`https://${Const.baseURL}/nsp-io-event`, {query: `token=${token}`});
        socketEvents(JSON.parse(userStorage))
    }, [])

    const socketEvents = (user) => {
        //ENVIAMOS EL CHANNELID A NULL PARA HACER EL JOIN SOBRE TODAS LAS CONVERSACIONES NO SOBRE UNA CONCRETA
        socketRef.current.emit('join', {
            userId: user.user.id,
        });

        socketRef?.current?.on('connect', () => {
            //console.log('CONECT TO SOCKET NAVBAR', socketRef.current.id);
        });

        socketRef?.current?.on('unread-messages', (e) => {
            //console.log('LOS MENSAJES SIN LEER NAVBAR', e);
            setUnreadMessages(e.hasOwnProperty('unreadMessages') ? e.unreadMessages : e.unreadTotal)
        })

        socketRef?.current?.on('welcome', () => {
            //console.log('WELCOME TO SOCKET NAVBAR');
        });

        socketRef?.current?.on('error', () => {
            //console.log('CONECT SOCKET ERROR NAVBAR');
        });

        socketRef?.current?.on('disconnect', () => {
            //console.log('CONECT SOCKET DISCONNECT NAVBAR');
            //removePushNotifications();
        });
    }

    // Recoge el usuario y comprueba el estado del coach para mostrar el puntito al lado de la foto
    const getUserProfile = () => {
        getProfile().then(user => {
            //console.log('Perfil recogido con exito', user.data);
            setImageUser(user.data.badge);
            if (user.data.status === 'Online' || user.data.status === 'Online') {
                setStatus('Disponible');

            } else if (user.data.status === 'connect')  {
                setStatus('Disponible');
            } else if (user.data.status === 'Offline' || user.data.status === 'offline') {
                setStatus('No disponible');
            } else {
                setStatus('Busy');
            }
        }).catch(err => {
            //console.log('ERROR al recoger el perfil', err);
        })
    }

    return (
        user !== null &&
        <div className={'containerNavBar'}>

            {/*<input id={'inputFile'} ref={inputRef} hidden={true} type={'file'} onChange={value => changeUserImage(value)}/>*/}
            <img className={'logoNavBar'} alt={''} src={ImageAssets.originalLogo}/>


            <div className={'containerItemsNavBar'}>
                <UserNavBar
                    name={`${user.user.name} ${user.user.lastname}`}
                    email={user.user.email}
                    image={imageUser}
                    status={status}
                    //press={value => inputRef.current.click()}
                />

                <div className={'containerButtonProfile'}>
                    <Button
                        text={t('changePass')}
                        press={() => navigate('/changePassword')}
                    />
                </div>

                {/*<StateUser value={status} changeValue={value => modifyStatus(value)}/>*/}

                <Menu route={location.pathname} unreadMessages={unreadMessages}/>
            </div>

            <CloseSesion/>
        </div>
    )
}

export default NavBar;
