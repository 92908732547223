import React, {useRef, useState} from 'react';

import './writer.css';
import {IconAssets} from '../../utils/ImageAssets';

// Muestra el componente del apartado para escribir y enviar mensajes en el chat
const Writer = ({value, changeValue, pressSender, sendMessage, writing, canWrite}) => {
    const [isWriting, setIsWriting] = useState(false)

    let textareaRef = useRef();

    //Comprobamos si el suuario esta escribiendo o no segun la cantidad de caracteres que hay en el input
    const userIsWriting = (value) => {
        if ((((value.length > 0 && !isWriting) || (value.length === 1)) || ((value.length % 3 === 0) && (value.length !== 0)))) {
            setIsWriting(true)
            writing(true)
        } else if (value.length === 0) {
            setIsWriting(false)
            writing(false)
        }
    }

    return (
        <div className={'containerWriter'}>
            <textarea
                ref={textareaRef}
                className={'inputWriter'}
                placeholder={canWrite ? 'Tu coach esta escribiendo' : 'Escribe tu mensaje'}
                type={'text'}
                //value={value}
                rows={10}
                multiline={true}
                readOnly={canWrite}
                onChange={value => userIsWriting(value.target.value)}
                onKeyPress={e => {
                    // console.log('KEY PRESED', e.code)
                    if (e.code === 'Enter') {
                            sendMessage(textareaRef);
                            setTimeout(() => {
                                textareaRef.current.value = null;
                            }, 150)
                    }
                }}
            />

            <div className={'senderWriter'} onClick={() => {
                    pressSender(textareaRef);
                    setTimeout(() => {
                        textareaRef.current.value = null;
                    }, 150)
                }}>
                <img src={IconAssets.sender} alt={''} />
            </div>
        </div>
    )
}

export default Writer;
